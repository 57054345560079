.wrapper {
    margin: 0 auto;
    padding: 0px;
    min-height: 390px;
    max-width: 1200px;
}

.card {
    margin: 25px;
    padding: 20px 40px;
    border-width: 1px;
    border-color: rgb(214, 214, 214);
    border-style: solid;
    width: calc(100% - 52px);
}

.content-wrapper {
    zoom: 1;
    position: relative;
    margin-left: auto;
    margin-right: auto;
    min-height: 1px;
}

.image-wrapper {
    clear: both;
    padding: 0 0 30px 0;
}

.image-wrapper img {
    width: 100%;
}

.service_content .title-wrapper {
    padding-bottom: 25px;
    text-align: left;
}

.service_content .title-wrapper a {
    font-size: 33px;
    letter-spacing: 0px;
    text-transform: none;
    color: #383838;
    line-height: 1.2;
    font-weight: 800;
    font-family: 'PingFangZhongCuTi';
}

.wrapper ul li {
    list-style: disc;
    overflow-wrap: break-word;
}

.content-wrapper .divider {
    border-color: #1ebef0;
    border-bottom-width: 1px;
    border-bottom-style: solid;
}

.service_content .content-wrapper p {
    font-size: 16px;
    text-transform: none;
    color: #727272;
    line-height: 1.7;
    margin-bottom: 20px;
}

.button-wrapper {
    padding-left: 0px;
    padding-right: 0px;
    padding-bottom: 30px;
    text-align: right;
}

.button-wrapper a {
    color: #1ebef0;
    padding: 10px 20px 10px 20px;
    border-radius: 5px;
    border-color: #1ebef0;
    border-width: 1px;
    border-style: solid;
    text-transform: none;
    letter-spacing: 0.5px;
    font-size: 13px;
    font-weight: bold;
}

.service_content .ant-col {
    width: 50%;
}

.service_content .ant-row{
    max-width: 1200px;
}

@media screen and (max-width: 1200px){
    .service_content .ant-row{
        max-width: 1000px;
        margin: 0 auto;
    }
}

@media screen and (max-width: 768px){
    .service_content .ant-row{
        max-width: 600px;
    }

    .service_content .ant-col {
        width: 100%;
    }
}
