.bannerImg{
  height: 228px;
  overflow: hidden;
  position: relative;
  margin-top: -1px;
}

.bannerImg .wrap{
  position: relative;
}

.bannerImg .title{
  position: absolute;
  padding-left: 30px;
  color: #fff;
  z-index: 5;
  font-size: 40px;
  font-weight: bold;
  line-height: 228px;
}

.bannerImg img{
  width: 100%;
  transform: translateY(-50%);
  position: absolute;
  top: 50%;
}

@media screen and (max-width: 768px){
  .bannerImg{
    height: 158px;
  }

  .bannerImg .title{
    line-height: 158px;
  }
}
