.navBar{
  background-color: #000;
  position: sticky;
  top: 0;
  left: 0;
  z-index: 6;
}

.navBar .ant-menu-horizontal{
  border-bottom: none;
}

@media screen and (max-width: 768px){
  .navBar{
    display: none;
  }
}