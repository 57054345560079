.news_content{
  padding: 100px 0 60px;
}

.news_content .news_card{
  margin: 1rem 0;
}

.news_content .news_card .news_card_date{
  margin-bottom: .5rem;
  font-family: Arial;
  letter-spacing: 1px;
  color: #888;
}

.news_content .news_card .news_card_date .anticon{
  color: #1ebef0;
  font-size: 16px;
}

.news_content .news_card .news_card_date span{
  margin: 0 .2rem;
  vertical-align: middle;
}

.news_content .news_card .ant-card-cover{
  overflow: hidden;
}

.news_content .news_card .ant-card-cover img{
  width: 100%;
  transform: scale(1);
  transition: transform .3s;
}

.news_content .news_card .ant-card-cover:hover img{
  transform: scale(1.1);
}

.news_content .news_card .news_card_title{
  font-weight: bold;
  font-size: 22px;
  transition: color .3s;
}

.news_content .news_card .news_card_title:hover{
  color: #1ebef0;
}

.news_content .news_card .news_card_description{
  font-size: 16px;
  color: #888;
}

.news_content .news_card .news_card_btn{
  margin: 1.5rem 0 .5rem;
}

@media screen and (max-width: 768px){
  .news_content{
    padding: 40px 0 60px;
    width: 90%;
    margin: 0 auto;
  }
}