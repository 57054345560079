.blog_content .blog_left{
  padding: 4rem 2.5rem 4rem 2rem;
  background: #f2f2f2;
  margin-top: 1px;
}

.blog_content .blog_left .blog_card .ant-card-cover{
  /* height: 415px; */
  overflow: hidden;
}

.blog_content .blog_left .blog_card .ant-card-cover img{
  width: 100%;
  transform: scale(1);
  margin-top: -30px;
  transition: transform .3s;
}

.blog_content .blog_left .blog_card .ant-card-cover:hover img{
  transform: scale(1.05);
}

.blog_content .blog_left .blog_card .ant-card-body{
  padding: 3rem;
}

.blog_content .blog_left .blog_title{
  margin-bottom: 1.5rem;
}

.blog_content .blog_left .blog_title_time{
  padding-right: 1rem;
  border-right: 3px solid #1ebef0;
  letter-spacing: 1px;
}

.blog_content .blog_left .blog_title_date{
  font-weight: bold;
  font-size: 28px;
  justify-content: center;
}

.blog_content .blog_left .blog_title_month{
  justify-content: center;
  color: #999;
  font-size: 14px;
}

.blog_content .blog_left .blog_title_text .blog_card_title{
  padding: 0 2rem;
  font-size: 28px;
  line-height: 1.3;
  font-weight: bold;
}

.blog_content .blog_left .blog_card_date{
  padding: 0 2rem;
  margin-bottom: .5rem;
  font-weight: bold;
}

.blog_content .blog_left .blog_card_date .anticon{
  color: #1ebef0;
  font-size: 16px;
  margin-right: .5rem;
}

.blog_content .blog_left .blog_card_date span{
  color: #888;
  font-size: 14px;
  vertical-align: middle;
}

.blog_content .blog_left .blog_card .blog_card_btn{
  margin: 1.5rem 0 .5rem;
}

.blog_content .blog_left .blog_card_description{
  font-size: 16px;
}

.blog_content .blog_right{
  padding: 4rem 2rem 4rem 2.5rem;
}

.blog_content .blog_right .latest_blog_item{
  margin-bottom: 1rem;
}

.blog_content .blog_right .imgWrap{
  width: 75px;
  height: 75px;
  overflow: hidden;
  border-radius: 5px;
}

.blog_content .blog_right h3{
  font-weight: bold;
  margin-bottom: 2rem;
}

.blog_content .blog_right img{
  height: 100%;
  transform: translateX(-15%);
}

.blog_content .blog_right h4{
  color: #888;
  font-weight: bold;
}

.blog_content .blog_right h4:hover{
  color: #1ebef0;
}

.blog_content .blog_right .anticon{
  font-size: 15px;
}

.blog_content .blog_right span{
  color: #888;
  font-size: 13px;
  margin-left: .3rem;
}

@media screen and (max-width: 768px){
  .blog_content .blog_left .blog_card_date{
    padding: 0;
  }

  .blog_content .blog_left .blog_title_text .blog_card_title{
    padding: 0;
  }
}
