.footerContainer{
  background: #242424;
  padding: 70px 0 50px;
}

.footerContainer .content{
  display: flex;
  flex-flow: row wrap;
  padding: 0 15px;
}

.footerContainer .content > li{
  width: calc( 100% / 4);
  padding: 0 30px;
  border-left: 1px solid #474747;
}

.footerContainer .content > li:first-child{
  border: none;
}

.footerContainer .content > li > img{
  margin-bottom: 27px;
  max-width: 100%;
}

.footerContainer .content > li > .intro{
  font-size: 15px;
  color: #f7f7f7;
  margin-bottom: 29px;
  line-height: 1.7;
  letter-spacing: -0.4px;
}

.footerContainer .content > li > .menu_link li a{
  color: #b2b2b2;
  line-height: 2;
  font-size: 16px;
}

.footerContainer .content > li > .menu_link li a:hover{
  color: #1ebef0;
}

.footerContainer .content > li > .contact_info{
  color: #f7f7f7;
  font-size: 16px;
  line-height: 1.7;
  margin-bottom: 20px;
}

.footerContainer .content > li > .contact_info .email{
  margin-right: 14px;
  margin-bottom: 4px;
}

.footerContainer .content > li > .contact_info .phone{
  margin-right: 10.5px;
  margin-top: -8px;
  margin-left: -1px;
}

.footerContainer .content > li > .social_media{
  margin-top: 43px;
}

.footerContainer .content > li > .social_media li{
  display: inline-block;
  margin-right: 14px;
}

.footerComp .copyright{
  background: #131313;
  padding: 31px 0 30px;
  text-align: center;
  color: #838383;
  letter-spacing: 1px;
}

.ant-layout-footer{
  padding: 0;
}

@media screen and (max-width: 768px){
  .footerContainer .content > li{
    width: 100%;
    margin-bottom: 80px;
    padding: 0 15px;
    border: none;
  }

  .footerContainer .content > li:first-child{
    margin-bottom: 60px;
  }

  .footerContainer .content > li:last-child{
    margin-bottom: 40px;
  }

  .footerContainer .content > li > .intro{
    margin-bottom: 20px;
  }
}