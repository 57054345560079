.ourCeo_content{
  padding: 100px 0 60px;
}

.ourCeo_content .avatar{
  text-align: center;
  margin-bottom: 30px;
}

.ourCeo_content h3{
  text-align: center;
  font-weight: bold;
  font-size: 22px;
  color: #383838;
  letter-spacing: 1px;
  margin-bottom: 30px;
}

.ourCeo_content .divider_container{
  width: 100px;
  margin: 0 auto 30px;
  border-bottom: 1px solid #e6e6e6;
}

.ourCeo_content .divider_container .divider{
  content: ' ';
  display: block;
  width: 40px;
  margin: 0 auto -2px;
  border-bottom: 3px solid #e6e6e6;
}

.ourCeo_content .text{
  padding: 0 160px;
}

.ourCeo_content .text p{
  margin-bottom: 67px;
  color: #727272;
  font-size: 16px;
  line-height: 1.7;
}

.ourCeo_content .text p:first-of-type::first-letter{
  margin: 18px 20px 8px 10px;
  font-size: 48px;
  float: left;
  font-weight: bold;
  color: #333;
  line-height: 30px;
}

.ourCeo_content .text p:last-of-type{
  margin-bottom: 50px;
}

.ourCeo_content .sign{
  padding: 0 160px;
  text-align: right;
}

@media screen and (max-width: 768px){
  .ourCeo_content .text{
    padding: 0 30px;
  }

  .ourCeo_content .sign{
    padding: 0 30px;
    text-align: right;
  }
}