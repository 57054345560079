.page-wrapper {
    clear: both;
    overflow: hidden;
}

.core-wrapper {
    padding: 60px 0px 30px;
    position: relative;
    clear: both;
}

.bg-wrapper {
    position: absolute;
    top: 0px;
    right: 0px;
    bottom: 0px;
    left: 0px;
    overflow: hidden;
}

.bg {
    width: 100%;
    height: 100%;
    backface-visibility: hidden;
}

.container-wrapper {
    margin-right: auto;
    margin-left: auto;
}

.container {
    padding-left: 15px;
    padding-right: 15px;
    max-width: 1180px;
}

.clearfix {
    zoom: 1;
}

.slider-wrapper {
    padding: 0;
    margin: 0px auto;
    background: rgb(38, 38, 38);
    height: 725px;
    overflow: visible;
    position: relative;
    z-index: 0;
    width: 100%;
}

.index_content .image-wrapper {
    visibility: visible;
    display: block;
    overflow: hidden;
    width: 1349px;
    height: 725px;
    max-height: none;
    left: 0px;
}

.image-item-wrapper {
    border-style: solid;
    margin-right: auto;
    margin-left: auto;
    display: inline-block;
    max-width: 100%;
}

.image {
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
    width: 100%;
    height: 100%;
    overflow: hidden;
    z-index: 20;
    visibility: inherit;
    opacity: 1;
    position: absolute !important;
}

.slick-prev {
    left: 15px !important;
    z-index: 1000;
}

.slick-prev:before {
    font-size: 35px !important;
}

.slick-next {
    right: 15px !important;
    z-index: 1000;
}

.slick-next:before {
    font-size: 35px !important;
    margin: 0 -3rem !important;
}

.column {
    position: relative;
}

.column-content {
    position: relative;
    margin-left: auto;
    margin-right: auto;
    min-height: 1px;
}

.column-first {
    clear: left;
}

.column-15 {
    float: left;
    width: 25%;
}

.column-20 {
    float: left;
    width: 33.3333%;
}

.column-40 {
    float: left;
    width: 66.6666%;
}

.column-60 {
    float: none;
    width: 100%;
}

.left-align {
    text-align: left;
}

.center-align {
    text-align: center;
}

.title-item {
    padding: 0px 15px 0px 15px;
    position: relative;
}

.title-item h3 {
    font-size: 36px;
    font-weight: 300;
    letter-spacing: 0px;
    text-transform: none;
    color: #383838;
    display: inline-block;
    margin-top: 0px;
    margin-bottom: 0px;
    font-family: PingFangZhongCuTi;
    line-height: 1.2;
}

.title-item h6 {
    font-size: 32px;
    font-weight: 500;
    letter-spacing: 0px;
    text-transform: none;
    color: #ffffff;
    display: inline-block;
    margin-top: 0px;
    margin-bottom: 0px;
    font-family: PingFangZhongCuTi;
    line-height: 1.2;
}

.title-item span {
    font-size: 18px;
    font-style: normal;
    color: #ffffff;
    display: block;
    margin-top: 10px;
    font-family: PingFangZhongCuTi;
}

.divider-item {
    position: relative;
    z-index: 1;
    margin-bottom: 30px;
}

.divider-container {
    max-width: 106px;
    margin-right: auto;
    margin-left: 0px;
    position: relative;
}

.core-item-pd-lr {
    padding-right: 15px;
    padding-left: 15px;
}

.core-item-pd-b {
    padding-bottom: 70px;
}

.core-button {
    text-transform: none;
    letter-spacing: 0.5px;
    font-weight: bold;
    display: inline-block;
}

.core-content {
    display: inline-block;
    vertical-align: middle;
}

.blog-thumbnail {
    margin-bottom: 27px;
}

.media-image {
    /* line-height: 0; */
    /* position: relative; */
}

.zoom-on-hover {
    overflow: hidden;
}

.zoom-on-hover img {
    transform: scale(1);
    transition: transform .3s;
}

.zoom-on-hover:hover img {
    transform: scale(1.1);
}

.blog-item-holder {
    padding-bottom: 1px;
}

.blog-grid {
    margin-bottom: 50px;
}

.opacity-on-hover {
    background: #000;
}

.opacity-on-hover img {
    max-width: 100%;
    height: auto;
}

.opacity-on-hover:hover img {
    opacity: 0.5;
}

.blog-title {
    font-size: 25px;
    font-weight: 800;
    margin-bottom: 20px;
    font-size: 24px;
    font-family: PingFangZhongCuTi;
    line-height: 1.2;
}

.blog-title a{
    color: #343434;
}

.blog-title a:hover{
    color: #1ebef0;
}

.blog-content {
    margin-bottom: 20px;
    color: #727272;
    line-height: 1.7;
    font-size: 16px;
}

.clear {
    display: block;
    clear: both;
    visibility: hidden;
    line-height: 0;
    height: 0;
    zoom: 1;
}

.plain-text {
    display: inline-block;
    margin-top: 20px;
    color: #545454;
}

.plain-text i {
    margin-left: 10px;
}

.fa {
    font: normal normal normal 14px/1 FontAwesome;
    font-size: inherit;
    text-rendering: auto;
}

.fa-arrow-right {
    content: '\f178';
}

.core-flexslider {
    position: relative;
}

.gallery-list {
    text-align: center;
}

.partner-image {
    width: 224px;
    margin-right: 0px;
    float: left;
    display: block;
}

.news-area{
    padding: 90px 0 20px;
}

.news-area .bg-wrapper:first-of-type{
    background-color: #000;
    height: 605.5px;
}

.news-area .bg-wrapper:first-of-type .bg{
    height: 605.5px;
}

.news-area ul{
    width: 1600%;
    margin-top: 0px;
    margin-left: 0px;
}

.news-area ul li{
    width: 257.5px;
    margin-right: 30px;
    float: left;
}

.service-area{
    margin: 0;
    padding: 60px 150px 50px;
}

.service-area .column-20 > div{
    position: relative;
    margin: 20px;
    padding: 0 30px;
}

.service-area .column-20 img{
    width: 100%;
}

@media screen and (max-width: 768px){
    .slider-wrapper {
        height: 313px;
    }
    
    .index_content .image-wrapper {
        height: 313px;
    }

    .four-item{
        display: none;
    }

    .about-intro .column-20{
        width: 100%;
        padding: 0 15px;
    }

    .about-intro .column-40{
        width: 100%;
    }

    .news-area{
        padding: 90px 0 120px;
    }

    .news-area ul{
        width: 100%;
    }

    .news-area ul li{
        display: none;
    }

    .news-area ul li:first-child{
        display: block;
        width: 100%;
    }

    .news-area .bg-wrapper:first-of-type{
        height: 735.5px;
    }

    .news-area .bg-wrapper:first-of-type .bg{
        height: 735.5px;
    }

    .service-area{
        padding: 60px 0 50px;
    }

    .service-area .column-20{
        width: 100%;
    }

    .service-area .column-20 > div{
        padding: 0;
    }

    .blog-area .column-20{
        width: 100%;
    }

    .gallery-list a{
        display: inline-block;
    }
}