.navbarMobileComp{
  display: none;
}

.navbarMobileComp .toggle_btn{
  border: 2px solid #ddd;
  border-radius: 3px;
  padding: 6px;
  font-size: 14px;
}

.ant-drawer-content{
  background-color: #1f1f1f;
  padding: 40px 20px;
}

.ant-drawer-content-wrapper{
  max-width: 350px;
  width: 60% !important;
  position: absolute;
  right: 0px !important;
  top: 0;
  bottom: 0;
}

.ant-drawer-header-no-title{
  background: #1f1f1f;;
}

.ant-drawer-close{
  color: #979797;
  left: 0;
  font-size: 20px;
  background: #1f1f1f;
  position: static;
}

.ant-drawer-body{
  padding: 0 24px;
}

.menuList_mobile{
  background: #1f1f1f;
  border-right: none;
  margin-top: 10px;
}

.menuList_mobile li{
  margin: 0 !important;
  padding: 17px 0 !important;
  border-bottom: 1px solid #626262;
  line-height: 20px !important;
  height: auto !important;
}

.menuList_mobile li a{
  color: #979797;
  font-size: 12px;
}

.ant-layout{
  transform: translateX(0);
  transition: transform .3s;
}

.ant-layout.move{
  transform: translateX(-60%);
}

@media screen and (max-width: 768px){
  .navbarMobileComp{
    display: block;
  }

  .ant-menu-item-selected{
    background: #1f1f1f !important;
  }

  .navbarMobileComp .toggle_btn{
    color: #262626;
  }

  .anticon-close{
    color: #979797;
  }
}

