body{
  margin: 0;
  padding: 0;
  font-family: Microsoft JhengHei;
}

ul, li{
  list-style: none;
  margin-bottom: 0;
  padding: 0;
}

h2, p{
  margin-bottom: 0;
}

.wrap{
  max-width: 1180px;
  margin: 0 auto;
}

.ant-layout-content{
  background: #fff;
  margin-top: -1px;
}

.divider{
  border-bottom: 2px solid #e6e6e6;
}

.ant-form-item-label > label{
  width: 80px;
  margin-top: 13px;
}

.ant-form-item-label > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before{
  color: #555;
}

.ant-form-item-label > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::after{
  display: none;
}

.ant-spin-nested-loading{
  background: #000;
}

.ant-spin-nested-loading > div > .ant-spin .ant-spin-dot{
  top: 50vh;
}

.ant-spin-dot-item{
  background-color: #fff;
}

.ant-spin-nested-loading > div > .ant-spin .ant-spin-text{
  top: 50vh;
  text-shadow: 0 1px 2px #656565;
  color: #fff;
}

.ant-card-meta-title{
  white-space: unset;
}

@media screen and (max-width: 768px) {
  body{
    width: 100% !important;
  }
}