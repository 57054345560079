.partners_content{
  padding: 60px 0;
}

.partners_content h3{
  font-size: 22px;
  font-weight: bold;
  color: #383838;
  text-align: center;
  margin-bottom: 30px;
  letter-spacing: 1px;
}

.partners_content .divider{
  width: 100px;
  margin: 0 auto 30px;
  border-bottom: 1px solid #e6e6e6;
}

.partners_content .divider .divider_deco{
  content: ' ';
  display: block;
  width: 40px;
  margin: 0 auto -2px;
  border-bottom: 3px solid #e6e6e6;
}

.partners_content .ship_list, .partners_content .buy_list{
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  padding: 0 15px;
  margin-bottom: 80px;
}

.partners_content .buy_list{
  margin-bottom: 30px;
}

.partners_content .item{
  width: calc(100% / 5 - 80px);
  margin: 0 40px 30px;
}

@media screen and (max-width: 768px){
  .partners_content .item{
    width: calc(100% / 2 - 60px);
    margin: 0 30px;
    text-align: center;
  }

  .partners_content .item img{
    width: 100%;
    max-width: 150px;
  }
}