.newsPost_content .news_left{
  border-right: 1px solid #ddd;
  padding: 4rem 2.5rem 4rem 2rem;
}

.newsPost_content .news_left p{
  font-size: 16px;
  color: #777;
  margin: 1.5rem 0;
}

.newsPost_content .news_left img{
  width: 100%;
}

.newsPost_content .news_left .news_title{
  margin-bottom: 1rem;
}

.newsPost_content .news_left .news_title_time{
  padding-right: 1rem;
  border-right: 3px solid #1ebef0;
  letter-spacing: 1px;
}

.newsPost_content .news_left .news_title_date{
  font-weight: bold;
  font-size: 28px;
}

.newsPost_content .news_left .news_title_month{
  justify-content: center;
  color: #999;
}

.newsPost_content .news_left .news_title_text{
  padding: 0 2rem;
  font-size: 36px;
  line-height: 1.3;
  font-weight: bold;
}

.newsPost_content .news_left .news_date{
  padding: 0 2rem;
  margin-bottom: 2.5rem;
}

.newsPost_content .news_left .news_date .anticon{
  color: #1ebef0;
  font-size: 16px;
  margin-right: .5rem;
}

.newsPost_content .news_left .news_date span{
  color: #888;
  font-size: 12px;
  vertical-align: middle;
}

.newsPost_content .news_left .news_main{
  margin-bottom: 2rem;
}

.newsPost_content .news_left .news_page_control{
  border-top: 1px solid #ddd;
  border-bottom: 1px solid #ddd;
  padding: 2rem 0;
}

.newsPost_content .news_left .news_page_control a{
  color: #aaa;
}

.newsPost_content .news_left .news_page_control a:hover{
  color: #888;
}

.newsPost_content .news_right{
  padding: 4rem 2rem 4rem 2.5rem;
}

.newsPost_content .news_right .latest_news_item{
  margin-bottom: 1rem;
}

.newsPost_content .news_right .imgWrap{
  width: 75px;
  height: 75px;
  overflow: hidden;
  border-radius: 5px;
}

.newsPost_content .news_right h3{
  font-weight: bold;
  margin-bottom: 2rem;
}

.newsPost_content .news_right img{
  height: 100%;
  transform: translateX(-15%);
}

.newsPost_content .news_right h4{
  color: #888;
  font-weight: bold;
}

.newsPost_content .news_right h4:hover{
  color: #1ebef0;
}

.newsPost_content .news_right .anticon{
  font-size: 15px;
}

.newsPost_content .news_right span{
  color: #888;
  font-size: 13px;
  margin-left: .3rem;
}

@media screen and (max-width: 768px) {
  .newsPost_content .news_left{
    border-right: none;
  }

  .newsPost_content .news_left .news_title_text{
    padding: 0;
  }

  .newsPost_content .news_left .news_date{
    padding: 0;
  }

  .newsPost_content .news_right{
    padding: 0rem 2rem 4rem 2.5rem;
  }
  
}