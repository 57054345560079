.headerComp{
  background: #fff;
  height: auto;
  padding: 0;
}

.headerComp .wrap{
  padding: .642rem 30px;
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  align-items: center;
}

.headerComp .logo{
  max-width: 200px;
}

.headerComp .logo img{
  width: 100%;
}

.headerComp .lang_control{
  display: flex;
  flex-flow: row wrap;
  line-height: 1;
  margin-right: -8px;
}

.headerComp .lang_control li{
  margin: 0;
  padding: 5px 10px;
}

.headerComp .lang_control li button{
  background: #fff;
  border: none;
  cursor: pointer;
  color: #545454;
  font-size: 16px;
  padding: 0;
}

.headerComp .lang_control li button.active{
  color: #1ebef0;
}

@media screen and (max-width: 768px){
  .headerComp .wrap{
    padding: 15px 30px;
  }

  .headerComp .logo{
    max-width: 173px;
  }

  .headerComp .lang_control{
    display: none;
  }

  .ant-layout-header{
    line-height: 60px;
  }

  .ant-layout.sticky{
    position: sticky;
    top: 0;
    left: 0;
    z-index: 10;
  }

  .ant-layout.scale > .headerComp .wrap{
    padding: 7.5px 30px;
    box-shadow: 0 1px 2px rgb(0, 0, 0, 10%);
  }

  .ant-layout > .headerComp .logo img{
    width: 100%;
  }

  .ant-layout.scale > .headerComp .logo img{
    width: 60%;
  }
}