.wrapper-top {
    clear: both;
    padding: 100px 15px 30px 15px;
    position: relative;
    max-width: 1180px;
    zoom: 1;
    margin-left: auto;
    margin-right: auto;
}

.wrapper-top-left {
    position: relative;
    clear: left;
    float: left;
    width: 50%;
    min-height: 1px;
}

.wrapper-top-right {
    position: relative;
    float: left;
    width: 50%;
    min-height: 1px;
}

.wrapper-middle {
    clear: both;
    padding: 60px 0px 30px;
    position: relative;
    max-width: 1180px;
    overflow: hidden;
    clear: left;
    float: none;
    width: 100%;
    zoom: 1;
    margin-left: auto;
    margin-right: auto;
}

.wrapper-middle-left {
    position: relative;
    clear: both;
    float: left;
    width: 20%;
    zoom: 1;
    margin-left: auto;
    margin-right: auto;
    min-height: 1px;
    padding-left: 15px;
    padding-right: 15px;
    line-height: 0;
    text-align: right;
}

.wrapper-middle-right {
    position: relative;
    float: left;
    width: 80%;
    zoom: 1;
    margin-left: auto;
    margin-right: auto;
    min-height: 1px;
    padding: 0px 15px 30px 15px;
    text-align: left;
    font-size: 16px;
    color: #727272;
}

.wrapper-bottom {
    padding: 150px 0px 300px 0px;
    clear: both;
    position: relative;
}

.icon-wrapper {
    padding-bottom: 30px;
    text-align: center;
}

.title-wrapper {
    position: relative;
    text-align: center;
    padding-bottom: 25px;
}

.title {
    font-size: 25px;
    font-weight: 500;
    color: #1ebef0;
}

.content-wrapper {
    padding: 0px 15px 30px 15px;
    font-size: 16px;
    transform: none;
    font-family: Microsoft JhengHei, PingFangZhongCuTi, -apple-system, PingFang-SC-Regular, Arial;
    color: #727272;
    line-height: 1.7;
}

.content-wrapper h6 {
    font-family: PingFangZhongCuTi;
    margin-top: 0px;
    margin-bottom: 20px;
    line-height: 1.2;
    font-size: 18px;
    font-weight: bold;
    text-transform: none;
}

.content {
    text-align: left;
    margin-top: 0px;
    margin-bottom: 20px;
}

.image-wrapper {
    border-width: 0px;
    margin: 0px 0px 25px 0px;
    border-style: solid;
    display: inline-block;
    max-width: 100%;
    line-height: 0;
    position: relative;
}

.background-wrapper {
    background-color: #0a0a0a;
    position: absolute;
    top: 0px;
    right: 0px;
    bottom: 0px;
    left: 0px;
    overflow: hidden;
    height: 512.8px;
}

.background {
    opacity: 0.3;
    background-image: url('../img/aboutUs_background.jpg');
    background-size: cover;
    background-position: center center;
    background-attachment: fixed;
    height: 512.8px;
    backface-visibility: hidden;
}

.background-content-wrapper {
    position: relative;
    max-width: 1180px;
    zoom: 1;
    margin-left: auto;
    margin-right: auto;
}

.background-content-title {
    position: relative;
    zoom: 1;
    text-align: center;
    padding-left: 15px;
    padding-right: 15px;
    padding-bottom: 30px;
    font-family: PingFangZhongCuTi;
}

.background-content-title h3 {
    font-size: 22px;
    color: #ffffff;
    font-weight: 800;
    display: inline-block;
    text-transform: uppercase;
    letter-spacing: 1px;
    margin-top: 0px;
    margin-bottom: 0px;
    transition: color 200ms;
    line-height: 1.2;
}

.background-content-title span {
    font-size: 22px;
    color: #1EBEF0;
    font-weight: 800;
    text-transform: uppercase;
    letter-spacing: 1px;
    line-height: 1.2;
}

.counter-wrapper {
    position: relative;
    float: left;
    width: 25%;
}

.counter {
    padding: 0px 15px 30px 15px;
    text-align: center;
}

.counter-title {
    font-size: 38px;
    color: #ffffff;
    font-family: PingFangZhongCuTi;
    line-height: 1;
    margin-bottom: 25px;
}

.counter-title span {
    font-weight: 700;
}

.counter-wrapper .divider {
    width: 20px;
    margin: 0px auto 25px;
    border-bottom-width: 2px;
    border-bottom-style: solid;
    border-color: #393939;
}

.counter-text {
    color: #00baed;
    font-size: 16px;
    text-transform: uppercase;
}

@media screen and (max-width: 768px){
    .wrapper-top-left{
        width: 100%;
    }

    .wrapper-top-right{
        width: 100%;
    }

    .content-wrapper{
        padding: 0 30px 30px;
    }

    .wrapper-middle-left{
        width: 100%;
        padding-left: 30px;
        padding-right: 30px;
    }

    .wrapper-middle-right{
        width: 100%;
        padding: 0 30px 30px;
    }

    .wrapper-bottom{
        padding: 150px 0 720px;
    }

    .background-wrapper{
        height: 932.8px;
    }

    .background{
        height: 932.8px;
    }

    .counter-wrapper{
        width: 100%;
    }
}