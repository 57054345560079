.menuList{
  background-color: #000;
  color: #fff;
  font-size: 15px;
  padding: 0 7px;
}

.menuList li{
  padding: 0.71rem 0 !important;
  border: none !important;
  font-size: 16px;
  margin: 0 23px !important;
  letter-spacing: 0.5px;
}

.menuList li a{
  color: #fff !important;
  font-size: 15px;
  letter-spacing: 0.5px;
}

.menuList li a:hover{
  color: #1ebef0 !important;
}

.menuList li a.active{
  color: #1ebef0 !important;
}

.ant-menu-item a:hover{
  color: #1ebef0 !important;
}

.ant-menu-sub{
  padding: 5px 0 10px !important;
}

.ant-menu-sub li{
  background: #000 !important;
  padding-left: 25px !important;
  margin: 0 !important;
}

.ant-menu-sub a{
  color:#bebebe;
}

.ant-menu-sub a.active{
  color:#1ebef0;
}

.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected{
  background: #0e0e0e;
}

.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected a{
  color: #1ebef0 !important;
}

.ant-menu-horizontal > .ant-menu-item-selected a{
  color: #1ebef0 !important;
}

.ant-menu-submenu .ant-menu-submenu-title{
  font-size: 15px;
}

.ant-menu-submenu.active .ant-menu-submenu-title{
  color: #1ebef0 !important;
}

.ant-menu-submenu:hover .ant-menu-submenu-title{
  color: #1ebef0 !important;
}

.ant-menu-submenu-active .ant-menu-submenu-title{
  color: #1ebef0 !important;
}

.ant-menu-submenu-selected .ant-menu-submenu-title{
  color: #1ebef0 !important;
}

.ant-menu-submenu-popup > .ant-menu{
  background: #0e0e0e;
  min-width: 120px;
  position: absolute;
  left: -15px;
  top: 3px;
}