.ourTeam_content .leaderList{
  padding: 100px 0; 
  display: flex;
  flex-flow: row wrap;
}

.ourTeam_content .leaderList .item{
  width: calc(100% / 4 - 30px);
  margin: 0 15px;
}

.ourTeam_content .leaderList .item img{
  width: 100%;
  margin-bottom: 30px;
}

.ourTeam_content .leaderList .item h3{
  color: #727272;
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 20px;
  line-height: 1.7;
}

.ourTeam_content .leaderList .item p{
  color: #727272;
  font-size: 16px;
  line-height: 1.7;
}

.ourTeam_content .divider_container{
  max-width: 50px;
  margin-bottom: 30px;
}

@media screen and (max-width: 768px){
  .ourTeam_content .leaderList{
    flex-flow: column wrap;
  }

  .ourTeam_content .leaderList .item{
    width: calc(100% - 60px);
    margin: 0 30px 50px;
  }
}

