.contact_content{
  display: flex;
  flex-flow: row wrap;
  padding: 110px 15px 100px;
}

.contact_content .contact_form{
  margin: 0 20px;
  width: calc(100% / 3 * 2 - 40px);
  padding: 0 15px;
}

.contact_content .contact_form .divider_container:first-of-type{
  max-width: 270px;
  margin-bottom: 30px;
}

.contact_content .contact_form .ant-input{
  padding: 15px 22px;
  border-width: 1px 1px 2px 1px;
  border-color: #d7d7d7;
  font-size: 15px;
}

.contact_content .contact_form .contact_content_label > .ant-form-item-label{
  padding-top: 30px;
}

.contact_content .contact_form .ant-input::placeholder{
  color: #4e4e4e;
}

.contact_content .contact_form .submit_btn{
  background: #2f2f2f;
  border: none;
  padding: 15px 22px;
  height: auto;
  font-size: 15px;
  letter-spacing: 0.8px;
  margin-top: 1.5rem;
  width: 130px;
}

.contact_content .contact_info{
  margin: 0 20px;
  width: calc(100% / 3 - 40px);
  padding: 0 15px;
  color: #727272;
  font-size: 16px;
}

.contact_content h2{
  font-size: 22px;
  font-weight: 600;  
  margin-bottom: 15px;
}

.contact_content .divider_container:first-of-type{
  max-width: 135px;
}

.contact_content .divider_container{
  max-width: 45px;
}

.contact_content .hk, .contact_content .tw{
  padding: 30px 0;
}

.contact_content h3{
  color: #727272;
  font-size: 16px;
  margin-bottom: 10px;
}

.contact_content .contact_info p{
  margin-bottom: 10px;
  font-size: 16px;
}

.contact_content .contact_info svg{
  margin-right: 15px;
  vertical-align: middle;
  color: #222;
}

@media screen and (max-width: 768px){
  .contact_content{
    flex-flow: column wrap;
  }

  .contact_content .contact_form{
    margin: 0;
    width: 100%;
    margin-bottom: 97px;
  }

  .contact_content .contact_info{
    margin: 0;
    width: 100%;
  }

  .contact_content h2{
    line-height: 1;
  }
}